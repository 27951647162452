import { APP_ROUTES } from '@lib/route/constants';
import { Tenant } from '@lib/tenants/types';

export const L2_TRAVEL_SIM_CONFIG: Tenant = {
  config: {
    setup: {
      id: {
        local: undefined,
        demo: undefined,
        qa: undefined,
        prod: 'L2_TRAVEL_SIM',
        staging: 'L2_TRAVEL_SIM',
      },
      name: 'l2-travel-sim',
      cmsSubdomain: {
        local: undefined,
        demo: undefined,
        staging: 'l2travelsim-staging',
        qa: undefined,
        prod: 'l2travelsim-production',
      },
      ryftPublicKey: {
        local: undefined,
        demo: undefined,
        qa: undefined,
        prod: undefined,
        staging:
          'pk_sandbox_wCLdq9JLAk1H+gqjotrJ4Thr6VaUI3+2CTpruusTyVnFeE54vPMiF9B+hYVa0kyy',
      },
      paypalPublicKey: {
        local: undefined,
        demo: undefined,
        qa: undefined,
        prod: undefined,
        staging: undefined,
      },
      authTenantId: {
        local: undefined,
        demo: undefined,
        staging: 'pn48xvqn',
        qa: undefined,
        prod: 'pn48v4yn',
      },
      ocbDigitalBaseUrl: {
        demo: undefined,
        qa: undefined,
        prod: 'https://l2travelsim.com/digital/api',
        staging: 'https://staging.l2travelsim.com/digital/api',
      },
    },
  },
  cmsRoutes: [
    {
      type: 'page',
      path: APP_ROUTES.ROOT_UID.path,
    },
    { type: 'blog', path: APP_ROUTES.BLOG.path },
    {
      type: 'home-page',
      path: APP_ROUTES.HOME.path,
    },
    {
      type: 'privacy-policy',
      path: APP_ROUTES.PRIVACY_POLICY.path,
    },
    {
      type: 'available_products',
      path: APP_ROUTES.PLANS.path,
    },
    {
      type: 'terms-and-conditions',
      path: APP_ROUTES.TERMS_AND_CONDITIONS.path,
    },
  ],
  theme: {},
  seo: {
    displayName: 'L2 Travel Sim',
  },
};
